<template
  ><div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Prawie gotowe!
        </v-card-title>
        <v-card-text
          >Teraz sprawdź swoją skrzynkę email i kliknij w wysłany link - tam
          ustawisz hasło. Następnie przejdź do strony logowania
          (portal.pogotowie8klasisty.pl).</v-card-text
        >
        <v-card-actions>
          <v-btn text to="/sign-in">Przejdź do strony logowania</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Coś poszło nie tak
        </v-card-title>
        <v-card-text
          >Wystąpił błąd. Sprawdź, czy podałeś(aś) prawidłowy email, którym
          zapisałeś(aś) się na zajęcia. W razie wątpliwości napisz pod adres
          kontakt@pogotowie8klasisty.pl</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="errorDialog = false">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-col md="4">
        <h1>Cześć!</h1>
        <p>
          Pora ustawić hasło do Portalu Ósmoklasisty. Cała aplikacja znajduje
          się pod adresem <b>portal.pogotowie8klasisty.pl</b>.
        </p>
        <p>
          Wpisz poniżej swój adres email, a my wyślemy Ci mailem link do
          ustawienia hasła.
        </p>
        <v-alert type="warning" icon="mdi-alert-circle" text
          >Ważne: Podaj adres email, którym zapisałeś(aś) się na zajęcia.
        </v-alert>
        <v-text-field
          outlined
          label="Email"
          class="mt-10"
          v-model="email"
        ></v-text-field>
        <v-btn
          @click="sendPasswordRestLink"
          :loading="loading"
          color="primary"
          elevation="0"
          >Dalej</v-btn
        >
      </v-col></v-row
    >
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { auth } from "../firebase/init";

export default {
  data() {
    return {
      ...mapMutations(["changeNavVisibility"]),
      email: "",
      dialog: false,
      errorDialog: false,
      loading: false,
    };
  },
  methods: {
    sendPasswordRestLink() {
      this.loading = true;
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.loading = false;
          this.dialog = true;
        })
        .catch(() => {
          this.loading = false;
          this.errorDialog = true;
        });
    },
  },
  // hide navigation when mounted
  mounted() {
    this.changeNavVisibility(false);
  },
  destroyed() {
    this.changeNavVisibility(true);
  },
};
</script>

<style></style>
